import { OutgoingMessage } from "http";
import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

interface PaymentShippingProps {
  // Definiere die Prop-Typen hier
}

const PaymentShipping: FunctionComponent<PaymentShippingProps> = () => {
  // Implementiere deine Komponente hier
  return (
    <div className="mx-auto mt-[85px] flex w-full flex-col items-start justify-start lg:w-1/3">
      <div className="font-GeistBold text-left text-3xl">
        <h1>Zahlung & Versand</h1>
      </div>
      <hr className="mb-4 h-[1px] w-full bg-slate-300" />
      <p className="mt-8 text-3xl text-gray-600">Versandbedingungen</p>
      <div className="relative flex w-full flex-col items-start justify-start gap-6 rounded-xl border-[2px] border-blue-900 bg-gray-50 py-6 pl-10 pr-2 text-gray-800 shadow-lg">
        <span className="absolute left-0 top-0 h-full w-[12px] rounded-bl-xl rounded-tl-xl bg-blue-900"></span>
        <p className="max-w-[90ch] text-base">
          Die Lieferung erfolgt im Inland (Deutschland) und in die nachstehenden
          Länder:
        </p>
        <div className="mx-auto flex w-full flex-wrap items-start justify-start gap-2">
          <span className="flex items-center justify-center rounded-xl bg-blue-200 px-2 text-blue-700">
            Österreich
          </span>
          <span className="flex items-center justify-center rounded-xl bg-blue-200 px-2 text-blue-700">
            Belgien
          </span>
          <span className="flex items-center justify-center rounded-xl bg-blue-200 px-2 text-blue-700">
            Bulgarien
          </span>
          <span className="flex items-center justify-center rounded-xl bg-blue-200 px-2 text-blue-700">
            Zypern
          </span>
          <span className="flex items-center justify-center rounded-xl bg-blue-200 px-2 text-blue-700">
            Tschechische Republik
          </span>
          <span className="flex items-center justify-center rounded-xl bg-blue-200 px-2 text-blue-700">
            Dänemark
          </span>
          <span className="flex items-center justify-center rounded-xl bg-blue-200 px-2 text-blue-700">
            Estland
          </span>
          <span className="flex items-center justify-center rounded-xl bg-blue-200 px-2 text-blue-700">
            Finnland
          </span>
          <span className="flex items-center justify-center rounded-xl bg-blue-200 px-2 text-blue-700">
            Großbritanien
          </span>
          <span className="flex items-center justify-center rounded-xl bg-blue-200 px-2 text-blue-700">
            Griechenland
          </span>
          <span className="flex items-center justify-center rounded-xl bg-blue-200 px-2 text-blue-700">
            Ungarn
          </span>
          <span className="flex items-center justify-center rounded-xl bg-blue-200 px-2 text-blue-700">
            Irland
          </span>
          <span className="flex items-center justify-center rounded-xl bg-blue-200 px-2 text-blue-700">
            Italien
          </span>
          <span className="flex items-center justify-center rounded-xl bg-blue-200 px-2 text-blue-700">
            Liechtenstein
          </span>
          <span className="flex items-center justify-center rounded-xl bg-blue-200 px-2 text-blue-700">
            Litauen
          </span>
          <span className="flex items-center justify-center rounded-xl bg-blue-200 px-2 text-blue-700">
            Luxemburg
          </span>
          <span className="flex items-center justify-center rounded-xl bg-blue-200 px-2 text-blue-700">
            Lettland
          </span>
          <span className="flex items-center justify-center rounded-xl bg-blue-200 px-2 text-blue-700">
            Niederlande
          </span>
          <span className="flex items-center justify-center rounded-xl bg-blue-200 px-2 text-blue-700">
            Polen
          </span>
          <span className="flex items-center justify-center rounded-xl bg-blue-200 px-2 text-blue-700">
            Rumänien
          </span>
          <span className="flex items-center justify-center rounded-xl bg-blue-200 px-2 text-blue-700">
            Serbien
          </span>
          <span className="flex items-center justify-center rounded-xl bg-blue-200 px-2 text-blue-700">
            Schweden
          </span>
          <span className="flex items-center justify-center rounded-xl bg-blue-200 px-2 text-blue-700">
            Slowenien
          </span>
          <span className="flex items-center justify-center rounded-xl bg-blue-200 px-2 text-blue-700">
            Slowakei{" "}
          </span>
        </div>
        <h1>
          Lieferungen in andere Länder erfolgen nur per bestätigter Anfrage.
        </h1>
        <Link
          to="/contact"
          className="flex items-center justify-center gap-1 text-blue-400 underline"
        >
          <p>Wenden Sie sich an unser Kontakformular</p>
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.64645 11.3536C3.45118 11.1583 3.45118 10.8417 3.64645 10.6465L10.2929 4L6 4C5.72386 4 5.5 3.77614 5.5 3.5C5.5 3.22386 5.72386 3 6 3L11.5 3C11.6326 3 11.7598 3.05268 11.8536 3.14645C11.9473 3.24022 12 3.36739 12 3.5L12 9.00001C12 9.27615 11.7761 9.50001 11.5 9.50001C11.2239 9.50001 11 9.27615 11 9.00001V4.70711L4.35355 11.3536C4.15829 11.5488 3.84171 11.5488 3.64645 11.3536Z"
              fill="currentColor"
              fillRule="evenodd"
              clipRule="evenodd"
            ></path>
          </svg>
        </Link>
      </div>
      <div className="flex flex-col items-start justify-start gap-2">
        <h1 className="mt-8 text-3xl text-gray-600">Lieferfristen</h1>
        <p className="text-base">
          Soweit im jeweiligen Angebot keine andere Frist angegeben ist, erfolgt
          die Lieferung der Ware im Inland (Deutschland) innerhalb von 3 - 5
          Tagen, bei Auslandslieferungen innerhalb von 5 - 10 Tagen nach
          Vertragsschluss (bei vereinbarter Vorauszahlung nach dem Zeitpunkt
          Ihrer Zahlungsanweisung). Beachten Sie, dass an Sonn- und Feiertagen
          keine Zustellung erfolgt. Haben Sie Artikel mit unterschiedlichen
          Lieferzeiten bestellt, versenden wir die Ware in einer gemeinsamen
          Sendung, sofern wir keine abweichenden Vereinbarungen mit Ihnen
          getroffen haben. Die Lieferzeit bestimmt sich in diesem Fall nach dem
          Artikel mit der längsten Lieferzeit den Sie bestellt haben.
        </p>
        <p className="text-base">
          Bei Selbstabholung informieren wir Sie per E-Mail über die
          Bereitstellung der Ware und die Abholmöglichkeiten. In diesem Fall
          werden keine Versandkosten berechnet.
        </p>
        <p className="mt-8 text-3xl text-gray-600">Zahlung</p>
        <div className="mb-16 flex w-max items-start justify-start gap-4">
          <div className="flex flex-col">
            <p className="text-gray-600">Zahlungsmöglichkeiten</p>
            <ul className="list-inside list-disc">
              <li>Barzahlung bei Abholung</li>
              <li>Vorkasse per Überweisung</li>
              <li>Zahlung per Nachnahme (zzgl. Nachnahmegebühr 0 €)</li>
              <li>Zahlung per PayPal</li>
              <li>
                Zahlung per PayPal (Kreditkarte, Lastschrift, ggf. Rechnung)
              </li>
            </ul>
          </div>
          <div className="flex flex-col">
            <p className="text-gray-600">Bankdaten</p>
            <div className="relative flex w-max items-start justify-start rounded-xl border-[2px] border-blue-900 bg-gray-50 p-4 shadow-xl">
              <span className="absolute left-0 top-0 h-full w-[12px] rounded-bl-xl rounded-tl-xl bg-blue-900"></span>
              <ul className="pl-6 text-blue-900">
                <li>Bank: Sparkasse Vogtland</li>
                <li>IBAN: DE09 8705 8000 3812 0027 51</li>
                <li>BIC : WELADED1PLX</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentShipping;
