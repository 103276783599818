import React, { useEffect } from "react";
import logo from "../../../shop/assets/images/juwelier-bucher.png";
import { Link } from "react-router-dom";

interface ParallaxProps {
  imageUrl: string;
}

const WelcomeParallax: React.FC<ParallaxProps> = ({ imageUrl }) => {
  const initialOffset = -280;
  useEffect(() => {
    const handleScroll = () => {
      const parallax = document.getElementById("parallax");
      if (!parallax) return;

      if (window.innerWidth >= 1280) {
        const scrollPosition = window.pageYOffset;
        parallax.style.backgroundPositionY = `${
          initialOffset + scrollPosition * 0.5
        }px`;
      } else {
        parallax.style.backgroundPositionY = `0`;
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial call to set position correctly on page load

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      id="parallax"
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundAttachment: window.innerWidth >= 1280 ? "absolute" : "scroll",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        overflow: "hidden",
        backgroundPositionY: `${initialOffset}px`,
      }}
      className="flex h-[calc(100vh-65px)] items-center justify-start"
    >
      <div className=" mx-auto flex w-[90%] flex-col items-start justify-start text-gray-50 lg:ml-[25%] lg:w-full xl:w-1/2 xl:p-0">
        <span className="font-GeistLight mt-8 flex flex-col items-start justify-start gap-6 text-lg xl:text-4xl">
          <img src={logo} className="h-16 w-32" alt="" />
          <span className="font-Geist text-xl text-gray-200 lg:text-4xl">
            Handwerkskunst seit 1932
          </span>
          <p className="font-GeistLight text-md text-left font-medium text-gray-50 lg:text-xl xl:max-w-[50ch]">
            Mit der Tradition einer beinahe 100-jährigen Firmengeschichte und
            dem Service eines modernen Unternehmens, bieten wir Ihnen in
            angenehmer Atmosphäre freundliche und kompetente Beratung. Wir sind
            stets auf der Suche nach inspirierenden Ideen, um das Unternehmen
            mit viel Energie in die Zukunft zu begleiten.
          </p>
        </span>
        <div className="mt-20 flex flex-col items-start justify-center gap-4 lg:flex-row lg:items-center">
          <Link
            className="border-sidebarGray-400 bg-sidebarGray-700 hover:bg-sidebarGray-900 flex items-center justify-center gap-4 rounded-full border-[1px] px-4 py-2 backdrop-blur-xl"
            to={"/UHREN"}
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.49998 0.5C5.49998 0.223858 5.72383 0 5.99998 0H7.49998H8.99998C9.27612 0 9.49998 0.223858 9.49998 0.5C9.49998 0.776142 9.27612 1 8.99998 1H7.99998V2.11922C9.09832 2.20409 10.119 2.56622 10.992 3.13572C11.0116 3.10851 11.0336 3.08252 11.058 3.05806L11.858 2.25806C12.1021 2.01398 12.4978 2.01398 12.7419 2.25806C12.986 2.50214 12.986 2.89786 12.7419 3.14194L11.967 3.91682C13.1595 5.07925 13.9 6.70314 13.9 8.49998C13.9 12.0346 11.0346 14.9 7.49998 14.9C3.96535 14.9 1.09998 12.0346 1.09998 8.49998C1.09998 5.13362 3.69904 2.3743 6.99998 2.11922V1H5.99998C5.72383 1 5.49998 0.776142 5.49998 0.5ZM2.09998 8.49998C2.09998 5.51764 4.51764 3.09998 7.49998 3.09998C10.4823 3.09998 12.9 5.51764 12.9 8.49998C12.9 11.4823 10.4823 13.9 7.49998 13.9C4.51764 13.9 2.09998 11.4823 2.09998 8.49998ZM7.99998 4.5C7.99998 4.22386 7.77612 4 7.49998 4C7.22383 4 6.99998 4.22386 6.99998 4.5V9.5C6.99998 9.77614 7.22383 10 7.49998 10C7.77612 10 7.99998 9.77614 7.99998 9.5V4.5Z"
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
              ></path>
            </svg>
            Entdecken Sie unsere Uhren
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.18194 4.18185C6.35767 4.00611 6.6426 4.00611 6.81833 4.18185L9.81833 7.18185C9.90272 7.26624 9.95013 7.3807 9.95013 7.50005C9.95013 7.6194 9.90272 7.73386 9.81833 7.81825L6.81833 10.8182C6.6426 10.994 6.35767 10.994 6.18194 10.8182C6.0062 10.6425 6.0062 10.3576 6.18194 10.1819L8.86374 7.50005L6.18194 4.81825C6.0062 4.64251 6.0062 4.35759 6.18194 4.18185Z"
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
              ></path>
            </svg>
          </Link>
          <Link
            className="border-sidebarGray-400 bg-sidebarGray-700 hover:bg-sidebarGray-900 flex items-center justify-center gap-4 rounded-full border-[1px] px-4 py-2 backdrop-blur-xl"
            to={"/SCHMUCK"}
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.53731 0.826873C7.5125 0.824392 7.4875 0.824392 7.46269 0.826873L3.96269 1.17687C3.85672 1.18747 3.76025 1.24262 3.69736 1.32857L0.699771 5.42528C0.654046 5.48627 0.62647 5.56165 0.625057 5.6434C0.625019 5.6456 0.625 5.64781 0.625 5.65001C0.625 5.65052 0.625001 5.65103 0.625003 5.65153C0.625363 5.74237 0.658021 5.82559 0.712087 5.8903L7.21042 13.7883C7.28165 13.8749 7.38789 13.925 7.5 13.925C7.61211 13.925 7.71835 13.8749 7.78958 13.7883L14.2879 5.89031C14.342 5.8256 14.3746 5.74237 14.375 5.65153C14.375 5.65103 14.375 5.65052 14.375 5.65001C14.375 5.64849 14.375 5.64697 14.375 5.64545C14.3749 5.63963 14.3747 5.63382 14.3744 5.62801C14.37 5.55219 14.3431 5.48242 14.3002 5.42527L11.3026 1.32857C11.2397 1.24262 11.1433 1.18747 11.0373 1.17687L7.53731 0.826873ZM10.925 5.27501V5.25236L10.9223 5.27501H10.925ZM10.9342 5.17498H13.1877L11.2495 2.5261L10.9342 5.17498ZM10.5707 1.88395L8.04432 1.63131L10.1747 5.21034L10.5707 1.88395ZM6.95568 1.63131L4.42931 1.88395L4.82531 5.21034L6.95568 1.63131ZM3.75046 2.5261L1.81226 5.17498H4.0658L3.75046 2.5261ZM1.79416 6.02501L6.75861 12.0587L4.22371 6.0669C4.21787 6.0531 4.21314 6.0391 4.20949 6.02501H1.79416ZM5.15055 6.02501L7.49999 12.1085L9.84943 6.02501H9.78681H5.21319H5.15055ZM10.7905 6.02501C10.7869 6.0391 10.7821 6.0531 10.7763 6.0669L8.24139 12.0587L13.2058 6.02501H10.7905ZM7.5 2.18317L9.34038 5.27501H5.65962L7.5 2.18317Z"
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
              ></path>
            </svg>
            Entdecken Sie unseren Schmuck
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.18194 4.18185C6.35767 4.00611 6.6426 4.00611 6.81833 4.18185L9.81833 7.18185C9.90272 7.26624 9.95013 7.3807 9.95013 7.50005C9.95013 7.6194 9.90272 7.73386 9.81833 7.81825L6.81833 10.8182C6.6426 10.994 6.35767 10.994 6.18194 10.8182C6.0062 10.6425 6.0062 10.3576 6.18194 10.1819L8.86374 7.50005L6.18194 4.81825C6.0062 4.64251 6.0062 4.35759 6.18194 4.18185Z"
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
              ></path>
            </svg>
          </Link>
        </div>
      </div>
      <button
        className="absolute bottom-8 left-[calc(50%-12.5px)]  hidden md:block"
        onClick={() => {
          const scrollTarget = document.getElementById("scroll-entry");
          if (scrollTarget) {
            const topPosition =
              scrollTarget.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = topPosition - 65;
            window.scrollTo({
              top: offsetPosition,
              behavior: "smooth",
            });
          }
        }}
      >
        <svg
          width="35"
          height="35"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.85355 2.14645C3.65829 1.95118 3.34171 1.95118 3.14645 2.14645C2.95118 2.34171 2.95118 2.65829 3.14645 2.85355L7.14645 6.85355C7.34171 7.04882 7.65829 7.04882 7.85355 6.85355L11.8536 2.85355C12.0488 2.65829 12.0488 2.34171 11.8536 2.14645C11.6583 1.95118 11.3417 1.95118 11.1464 2.14645L7.5 5.79289L3.85355 2.14645ZM3.85355 8.14645C3.65829 7.95118 3.34171 7.95118 3.14645 8.14645C2.95118 8.34171 2.95118 8.65829 3.14645 8.85355L7.14645 12.8536C7.34171 13.0488 7.65829 13.0488 7.85355 12.8536L11.8536 8.85355C12.0488 8.65829 12.0488 8.34171 11.8536 8.14645C11.6583 7.95118 11.3417 7.95118 11.1464 8.14645L7.5 11.7929L3.85355 8.14645Z"
            fill="#fff"
            fillRule="evenodd"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
    </div>
  );
};

export default WelcomeParallax;
