import { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";
import { CartContext } from "../../../utils/CartContext";
import { Product } from "../../../utils/types";
import "./ProductDetail.css";
import { formatPrice } from "../../../common/components/layouts/defaultLayout/DefaultLayout";

const ProductDetail = () => {
  const { id } = useParams();
  const { addToDatabaseCart } = useContext(CartContext);
  const [product, setProduct] = useState<Product | null>(null);
  const [modalImage, setModalImage] = useState<string | null>(null);
  const [, setCurrentIndex] = useState(0);
  const sliderRef = useRef<any>(null);
  const loggedInUser = localStorage.getItem("loggedInUser");

  const fetchProduct = async () => {
    try {
      const response = await fetch(`/api/aeb/products/${id}`);
      const data: Product = await response.json();
      setProduct(data);
    } catch (error) {
      console.error("Fehler beim Laden des Produkts:", error);
    }
  };

  useEffect(() => {
    fetchProduct();
  }, [id]);

  const calculateDiscount = (standardPrice: number) => {
    return Math.round((standardPrice * 0.95 + Number.EPSILON) * 100) / 100;
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    centerPadding: "0",
    centerMode: true,
    afterChange: (currentIndex: number) => setCurrentIndex(currentIndex),
    customPaging: function (i: number) {
      return (
        <span className="h-12 w-12 xl:h-20 xl:w-20">
          <img
            src={`data:image/jpeg;base64,${product?.images[i].imageData}`}
            className="h-12 w-12 xl:h-20 xl:w-20"
            alt={`thumbnail-${product?.productName}`}
          />
        </span>
      );
    },
  };

  const renderArticles = () => {
    if (!product) return null;
    const articles = [
      { title: "WERKART", content: product.clockworkType },
      { title: "MATERIAL-KÖRPER", content: product.bodyMaterialBezel },
      { title: "ARMBAND", content: product.wristband },
      { title: "ARMBAND-FARBE", content: product.wristbandColor },
      { title: "ARMBAND-MATERIAL", content: product.wristbandMaterial },
      { title: "SCHLIEßE", content: product.wristbandClosure },
      { title: "MATERIAL-SCHLIEßE", content: product.wristbandClosureMaterial },
      { title: "ZIFFERBLATT-FARBE", content: product.clockFaceColor },
      { title: "ZIFFERBLATT-TYP", content: product.clockFaceType },
      { title: "FINISH", content: product.finish },
      {
        title: "ABMESSUNGEN",
        content: product.height ? `${product.height} cm` : undefined,
      },
      {
        title: "DURCHMESSER",
        content: product.diameter ? `${product.diameter} cm` : undefined,
      },
      { title: "WASSERDICHTE", content: product.waterResistance },
    ];

    return (
      <div className="">
        <hr className="max-w-1/2 mx-auto mb-8 h-[1px] w-full bg-gray-300" />
        <div className="xl:max-w-1/2 mx-auto flex w-full flex-col items-center justify-center gap-12 md:max-w-[80%] lg:flex-row lg:items-start lg:justify-center lg:gap-0">
          <div className="flex h-full w-full flex-col items-start justify-between lg:w-1/2">
            <div className=" flex flex-col items-center justify-start">
              <h1 className="w-3/4 text-left text-2xl">Details</h1>
              <p className="font-Geist mt-6 w-3/4 text-base">
                {product?.additionalInfo}
              </p>
            </div>
          </div>
          <div className="flex w-full flex-col items-center justify-center gap-6 lg:w-1/2 lg:items-start lg:justify-center">
            {articles.map(
              (article) =>
                article.content && (
                  <div className="flex flex-col items-center justify-center lg:items-start lg:justify-center">
                    <h1 className="font-GeistLight text-sm text-gray-400">
                      {article.title}
                    </h1>
                    <p className="font-Geist text-xl text-gray-900">
                      {article.content}
                    </p>
                  </div>
                ),
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="mb-[65px] mt-[65px] flex flex-col items-center justify-center">
        {product && (
          <div className="xl:max-w-1/2 relative mx-auto mb-[64px] flex h-auto w-full flex-col items-start justify-center md:max-w-[80%] lg:flex-row">
            <div className="pd__imgGallery w-full lg:w-1/2 xl:pr-8">
              <div className="flex flex-shrink flex-wrap items-center justify-center overflow-x-hidden">
                <Slider ref={sliderRef} {...settings} className="pd__slider">
                  {product.images.map((slide, index) => (
                    <div
                      key={index}
                      onClick={() =>
                        setModalImage(
                          `data:image/jpeg;base64,${slide.imageData}`,
                        )
                      }
                      className="flex items-center justify-center"
                    >
                      <img
                        src={`data:image/jpeg;base64,${slide.imageData}`}
                        alt={product.productName}
                        width={320}
                        height={320}
                        className="mx-auto object-cover"
                      />
                    </div>
                  ))}
                </Slider>
              </div>
              {modalImage && (
                <div
                  className="modal-background"
                  onClick={() => setModalImage(null)}
                >
                  <span onClick={() => setModalImage(null)}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 128 128"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_696_2)">
                        <path
                          d="M8 120L120 8.00002"
                          stroke="#fff"
                          strokeWidth="15"
                          strokeLinecap="round"
                        />
                        <path
                          d="M8 8L120 120"
                          stroke="#fff"
                          strokeWidth="15"
                          strokeLinecap="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_696_2">
                          <rect width="128" height="128" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <img
                    className="modal-content objec"
                    width={600}
                    height={600}
                    src={modalImage}
                    alt="Modal"
                  />
                </div>
              )}
            </div>
            <div className="mx-auto flex w-full flex-col items-center justify-between lg:w-1/2 lg:items-start">
              <div className="">
                <p className="font-GeistLight text-center text-gray-500 lg:text-left">
                  {product.brand}
                </p>
                <h1 className="font-Geist text-center text-3xl lg:text-left">
                  {product.productName}
                </h1>
                <div className="card__details-single flex flex-col gap-4">
                  <p className="mt-4 text-center text-base lg:text-left">
                    {product.saleText}
                  </p>
                </div>
              </div>
              <div className="flex flex-grow flex-col items-center justify-center lg:items-start lg:justify-start">
                <h3 className="my-6 flex text-center text-xl lg:text-left">
                  Preis:&nbsp;
                  <span className="font-bold">
                    {formatPrice(product.price.toString())}€
                  </span>
                  *
                </h3>
                <div className="flex flex-col items-center justify-center gap-2 lg:items-start">
                  <span className="flex items-center justify-start gap-4">
                    <span className="flex items-center justify-center rounded-lg bg-blue-500/20 px-2 py-1">
                      Lieferung: 2 - 3 Werktage
                    </span>
                  </span>
                </div>
              </div>
              <div className="mt-12 flex gap-8">
                {loggedInUser ? (
                  <button
                    className="flex items-center justify-center gap-2 rounded-lg border-[1px] border-gray-900 bg-gray-200 px-4 py-2"
                    onClick={() =>
                      addToDatabaseCart({
                        id: product.id,
                        quantity: 1,
                        product: product,
                      })
                    }
                  >
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 1C5 0.447715 5.44772 0 6 0H9C9.55228 0 10 0.447715 10 1V2H14C14.5523 2 15 2.44772 15 3V6C15 6.8888 14.6131 7.68734 14 8.23608V11.5C14 12.3284 13.3284 13 12.5 13H2.5C1.67157 13 1 12.3284 1 11.5V8.2359C0.38697 7.68721 0 6.88883 0 6V3C0 2.44772 0.447716 2 1 2H5V1ZM9 1V2H6V1H9ZM1 3H5H5.5H9.5H10H14V6C14 6.654 13.6866 7.23467 13.1997 7.6004C12.8655 7.85144 12.4508 8 12 8H8V7.5C8 7.22386 7.77614 7 7.5 7C7.22386 7 7 7.22386 7 7.5V8H3C2.5493 8 2.1346 7.85133 1.80029 7.60022C1.31335 7.23446 1 6.65396 1 6V3ZM7 9H3C2.64961 9 2.31292 8.93972 2 8.82905V11.5C2 11.7761 2.22386 12 2.5 12H12.5C12.7761 12 13 11.7761 13 11.5V8.82915C12.6871 8.93978 12.3504 9 12 9H8V9.5C8 9.77614 7.77614 10 7.5 10C7.22386 10 7 9.77614 7 9.5V9Z"
                        fill="currentColor"
                        fillRule="evenodd"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Zum Warenkorb hinzufügen
                  </button>
                ) : (
                  <div className="flex flex-col items-start justify-center gap-4">
                    <button className="flex cursor-not-allowed items-center justify-center gap-2 rounded-lg border-[1px] border-gray-900 bg-gray-400 px-4 py-2">
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 1C5 0.447715 5.44772 0 6 0H9C9.55228 0 10 0.447715 10 1V2H14C14.5523 2 15 2.44772 15 3V6C15 6.8888 14.6131 7.68734 14 8.23608V11.5C14 12.3284 13.3284 13 12.5 13H2.5C1.67157 13 1 12.3284 1 11.5V8.2359C0.38697 7.68721 0 6.88883 0 6V3C0 2.44772 0.447716 2 1 2H5V1ZM9 1V2H6V1H9ZM1 3H5H5.5H9.5H10H14V6C14 6.654 13.6866 7.23467 13.1997 7.6004C12.8655 7.85144 12.4508 8 12 8H8V7.5C8 7.22386 7.77614 7 7.5 7C7.22386 7 7 7.22386 7 7.5V8H3C2.5493 8 2.1346 7.85133 1.80029 7.60022C1.31335 7.23446 1 6.65396 1 6V3ZM7 9H3C2.64961 9 2.31292 8.93972 2 8.82905V11.5C2 11.7761 2.22386 12 2.5 12H12.5C12.7761 12 13 11.7761 13 11.5V8.82915C12.6871 8.93978 12.3504 9 12 9H8V9.5C8 9.77614 7.77614 10 7.5 10C7.22386 10 7 9.77614 7 9.5V9Z"
                          fill="currentColor"
                          fillRule="evenodd"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      Zum Warenkorb hinzufügen
                    </button>
                    <div className="flex flex-shrink-0 items-center justify-center gap-2 border-l-4 border-blue-800 bg-blue-200 px-2 pl-2">
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.4449 0.608765C8.0183 -0.107015 6.9817 -0.107015 6.55509 0.608766L0.161178 11.3368C-0.275824 12.07 0.252503 13 1.10608 13H13.8939C14.7475 13 15.2758 12.07 14.8388 11.3368L8.4449 0.608765ZM7.4141 1.12073C7.45288 1.05566 7.54712 1.05566 7.5859 1.12073L13.9798 11.8488C14.0196 11.9154 13.9715 12 13.8939 12H1.10608C1.02849 12 0.980454 11.9154 1.02018 11.8488L7.4141 1.12073ZM6.8269 4.48611C6.81221 4.10423 7.11783 3.78663 7.5 3.78663C7.88217 3.78663 8.18778 4.10423 8.1731 4.48612L8.01921 8.48701C8.00848 8.766 7.7792 8.98664 7.5 8.98664C7.2208 8.98664 6.99151 8.766 6.98078 8.48701L6.8269 4.48611ZM8.24989 10.476C8.24989 10.8902 7.9141 11.226 7.49989 11.226C7.08567 11.226 6.74989 10.8902 6.74989 10.476C6.74989 10.0618 7.08567 9.72599 7.49989 9.72599C7.9141 9.72599 8.24989 10.0618 8.24989 10.476Z"
                          fill="currentColor"
                          fillRule="evenodd"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <Link to={"/sign-in"}>
                        Melden Sie sich bitte an, um fortzufahren,
                        <br />
                        oder besuchen Sie uns vor Ort.
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {renderArticles()}
      </div>
    </>
  );
};

export default ProductDetail;
